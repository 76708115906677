<template>
  <div>
    <footer class="footer">
      <div class="content has-text-centered">
        <img src="@/assets/logo.png" style="max-height:5.75rem; "/>
        <p>
          <strong> Mon budget formation</strong> made with ❤ by <a href="https://matchers.fr">Matchers</a> 
        </p>
         © Fonticons, Inc.
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: "Footer",

}
</script>
