<template>
  <div>
    <b-navbar style="background: rgba(214,218,220,0.25)">
      <template #brand >
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="@/assets/logo.png" style="max-height:5.75rem; margin-left: 35px"/> 
        </b-navbar-item>
        <p style="align-self:center">By <a class="matchers" href="http://matchers.fr" target="_blank"> Matchers</a></p>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div v-if="!loggedIn" class="buttons">
            <a @click="$router.push('/credentials/')" class="button is-light" style="color: #3534A1; font-family: 'Montserrat Alternates', sans-serif; font-weight: bold">
              Login
            </a>
          </div>
          <div v-else class="buttons">
            <a @click="$router.push('/update/')" class="button is-light" style="color: #3534A1; font-family: 'Montserrat Alternates', sans-serif; font-weight: bold">
              Update
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="contenant is-max-desktop content ">
      <img src="@/assets/moulinette_home_illusatration.jpg" alt="..." >
      <p class="title texte_centrer is-size-6-mobile" style="font-family: 'Montserrat Alternates', sans-serif; font-weight: bold; color: beige;">{{text}}</p>
    </div>

  </div>
</template>

<script>
import {getCookie} from "@/utils/cookies";

export default {
  name: "Navbar",
  props: {
    text: String,
  },
  data() {
    return {
      name: "",
      loggedIn : false
    };
  },
  mounted() {
    if (getCookie("token")) {
      this.loggedIn = true
    }
  },
}
</script>

<style>
.contenant {
  position: relative;
  text-align: center;
  color: red;
}

.texte_centrer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.matchers {
  font-family: 'Montserrat Alternates', sans-serif; font-weight: bold;
  color: blue
}
</style>