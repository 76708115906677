<template>
  <div>

    <section>
      <div class="columns" style="margin-top:10px">
        <div class="column is-3"> </div>
        <div class="column"> 
          <b-field label="Trouver ma convention collective ou mon idcc" style="text-align:center">
            <b-autocomplete
                v-model="name"
                group-field="type"
                group-options="items"
                open-on-focus
                :data="filteredDataObj"
                @select="()=>{
                  option => (selected = option);
                }"
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-3"> </div>
      </div>
    </section>

  </div>
</template>

<script>
import {getAllOpcoGroupByOpco} from "@/utils/opco/getOpcoGroupByOpco.js";
export default {
  name: "AutocompleteIdcc",

  data() {
    return {
      data: [],
      name: '',
      selected: null,
      idcc: null,
    };
  },

  beforeMount() {
    getAllOpcoGroupByOpco().then(res=>{
      this.data = res.data
    })
  },


  computed: {
    filteredDataObj() {
      const newData = []
      this.data.forEach(element => {
        const items = element.items.filter(item =>
            item.toLowerCase().indexOf(this.name.toLowerCase()) >= 0)
        if (items.length) {
          newData.push({ type: element.type, items})
        }
      })
      return newData
    }
  },

  watch:{
    name: function(newValue, oldValue) {
      console.log(newValue,oldValue)
      this.onChange(this.name)
    }
  },


  methods: {
    onChange(name) {
      if(name){
        this.idcc = {
          'idcc' : name.split(' - ')[0],
          'nom' : name.split(' - ')[1]
        }
        this.$emit('onChange', this.idcc);
      }
    },
  }
}
</script>
<style>
.autocomplete {
    justify-content : center;
    border-color: #ffffff !important;
    box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
}
autocomplete {
    justify-content : center;
    border-color: #ffffff !important;
    box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
}
</style>